.dodSection {

    background-size: cover;
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;

}


h1.drinkOfDayTitle.hidden {
    filter: blur(15px);
    opacity: 0;
    transition: all 1s;
}


h1.drinkOfDayTitle.show {
    animation-duration: 2s;
    animation-name: animate-dod-text;
    color: var(--color-primary-font);
    font-size: clamp(2.5rem, 9.5vw, 11.5vw);
    font-weight: 900;
    margin-top: 4.5rem;
    text-align: center;
    filter: blur(0);
    opacity: 1;
}


.dodContainer {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
    margin-bottom: 22rem;
    /* margin bottom very large to hide gap created during parallex effect */
}


.dodLeftSide.hidden {
    opacity: 0;
    filter: blur(15px);
    transition: all 1s;
}


.dodLeftSide.show {
    align-items: center;
    animation-duration: 2s;
    animation-name: animate-dod-text;
    display: flex;
    filter: blur(0);
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    opacity: 1;

}

.todaysDrink {
    color: var(--color-primary-font);
    font-size: clamp(2rem, 4.5vw, 6.5vw);
    font-weight: 600;
    text-align: center;
}

.todaysDrinkofDay {
    font-size: clamp(1.25rem, 2.5vw, 4.5vw);
    font-weight: 300;
}

.dailyDrink {
    color: var(--color-primary-font);
    font-size: clamp(2rem, 4vw, 6vw);
    font-weight: 400;
    margin: 2rem 0 2rem 0;
    text-align: center;
}

.recipeButton button {
    background: transparent;
    border: 3px solid var(--color-primary-font);
    border-radius: 10px;
    color: var(--color-primary-font);
    cursor: pointer;
    font-size: clamp(1.25rem, 2vw, 10rem);
    font-weight: 700;

    outline: none;
    text-align: center;
    padding: .30rem 1.20rem;
    width: clamp(7rem, 12vw, 12rem);
}

.recipeButton:active {
    transform: translateY(3px);

}

.boxTitle {
    color: white;
    font-size: clamp(1.5rem, 2.5vw, 4.5rem);
    text-align: center;
    margin-bottom: 1rem;
}


.dodRightSide.hidden {
    opacity: 0;
    filter: blur(15px);
    transition: all 1s;
}


.dodRightSide.show {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    filter: blur(0px);
    opacity: 1;
    animation-duration: 2s;
    animation-name: animate-dod-right-text;
}


@keyframes animate-dod-text {
    0% {
        transform: translateX(-200%)
    }

    100% {
        transform: translateX(0%)
    }


}

@keyframes animate-dod-right-text {
    0% {
        transform: translateX(200%)
    }

    100% {
        transform: translateX(0%)
    }


}

@media (prefers-reduced-motion: reduce) {
    .show {
        animation: none;
    }
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {

    /* prevents gap on scroll from parallex */
    .dodSection {
        margin-bottom: -8rem;

    }


}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    .dodOuterContainer {
        padding-top: 4rem;
    }


    .dodContainer {
        display: flex;
        flex-direction: column;
    }

    .todaysDrinkofDay {
        display: none
    }

    .dailyDrink {
        margin-top: .15rem;
    }



    .recipeButton {
        margin-top: .10rem;
    }

    .boxTitle {
        margin-top: 1.5rem;
    }

}