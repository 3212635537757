.bannerContainer {
    background-color: red;
    border: solid red 3px;
    color: var(--color-primary-font);
    display: inline-block;
    font-family: 'lobster';
    font-size: 2rem;
    font-weight: 400;
    height: 3rem;
    position: relative;
    text-align: center;
    transition: var(--transition-in);
    width: 100vw;
}


.pageLoading {
    align-items: center;
    background-color: #605a5a;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;



}

.pageLoading span {
    animation-name: dots;
    animation-duration: 750ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    background-color: #ffd957;
    border-radius: 50%;
    display: inline-block;
    height: 25px;
    margin: 0 5px;
    width: 25px;
}

.pageLoading span:nth-child(2) {
    background-color: #57ffa0;
    animation-delay: 0.2s;

}

.pageLoading span:nth-child(3) {
    background-color: #ff8b57;
    animation-delay: 0.4s
}

.loadingText {
    color: var(--color-primary-font);
    display: inline-block;
    font-family: 'lobster';
    font-size: 2rem;
    font-weight: 400;
    margin-right: 1rem;

}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
    .pageLoading {
        margin-top: -8rem;
    }
}


/* =============== MEDIA QUERIES (SMALL LANDSCAPE) =============== */
@media (max-width: 670px) and (orientation: landscape) {}