/* = = = = = = = = = Privvacy Choice CSS = = = = = = = = = */
.privacyPopup {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    border: 4px solid var(--color-primary-font);
    border-radius: 10px;
    bottom: 0%;
    display: flex;
    flex-direction: column;
    gap: .75rem;
    justify-content: center;
    height: 42rem;
    height: clamp(30rem, 40rem, 80vh);
    margin: 1rem 0 1rem 0;
    right: 10%;
    padding: 0 1rem 0 1rem;
    position: fixed;
    width: clamp(20rem, 45rem, 45vw);
    z-index: 999;
}

.logoDiv {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.closeButton {
    color: var(--color-primary-font);
    cursor: pointer;
    background-color: transparent;
    font-size: 1.5rem;
    margin-right: 1rem;
}


.privacyChoiceContainer {

    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    margin: 1rem 0 1rem 0;
}

.privacyChoiceContainer p {
    font-weight: 400;
    margin-top: 1rem;
}

span.privacyChoiceSpan {
    color: blue;
}

span.privacyChoiceSpan span {
    color: var(--color-primary-font);
}


.privacyChoiceContainer a {
    text-align: center;
}

.privacyChoiceConfirmChoice {
    background-color: transparent;
    border: 3px solid var(--color-primary-font);
    border-radius: 10px;
    color: var(--color-primary-font);
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 700;
    outline: none;
    text-align: center;
    transition: var(--transition-in);
    padding: .25rem;
    width: 15rem;

}

.privacyChoiceConfirmChoice:active {
    transform: translateY(.5rem);
}

.privacyChoiceOptButton {
    background-color: transparent;
    border: 2px solid var(--color-primary-font);
    border-radius: 10px;
    color: var(--color-primary-grey);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    outline: none;
    text-align: center;
    transition: var(--transition-in);
    padding: .5rem .5rem;
    width: 15rem;
}

.privacyChoiceOptButton:active {
    transform: translateY(.5rem);
}


/* = = = = = = = = = END OF Privacy Choice CSS = = = = = = = = = */


.privacyPolicySection {
    align-items: center;
    background-color: black;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    margin-top: -5rem;
    width: 100%;
}

.privacyPolicyTitle h1 {
    font-size: clamp(4rem, 4vw, 6vw);
    margin-top: 10rem;
    text-align: center;

}



.privacyContainer {
    color: var(--color-primary-font);
    display: flex;
    align-items: center;
    justify-content: center;
}

.privacyParagraphsContainer {
    margin-top: 3rem;
    margin-bottom: 3rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    margin-top: 3rem;
    width: 70%;
}


.privacyParagraphsContainer p {
    font-size: clamp(1rem, 1.25vw, 3vw);
    text-align: justify;
}

.privacyParagraphsContainer h3 {
    margin-top: 1.25rem;
    text-decoration: underline;

}




ol.privacyOrderedList li {
    list-style-type: decimal;
}

ol.privacyBulletListInner li {
    list-style-type: disc;
}

.sitePhoneNumber span,
.siteEmail span,
.siteAddress span {
    font-weight: bold;
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
    .privacyPolicySection {
        margin-top: -11rem;

    }


    /* = = = = = = = = = START OF Privacy Choice CSS = = = = = = = = = */
    .privacyPopup {
        height: 96vh;
        overflow: hidden;
        overflow-y: scroll;

    }

    .privacyChoiceContainer {
        gap: 1rem;
        margin: .5rem 0 .5rem 0;
    }



    /* = = = = = = = = = END OF Privacy Choice CSS = = = = = = = = = */
}