.bannerContainer {
    background-color: red;
    border: solid red 3px;
    color: var(--color-primary-font);
    display: inline-block;
    font-family: 'lobster';
    font-size: 2rem;
    font-weight: 400;
    height: 3rem;
    position: relative;
    text-align: center;
    transition: var(--transition-in);
    width: 100vw;
}


.navbar {
    align-items: center;
    background-color: rgba(16, 14, 14, 0.727);
    display: flex;
    height: 5rem;
    /* left: 0; */
    position: sticky;
    top: 0;
    width: 100vw;
    z-index: 300;
}


form.searchId {
    margin: 0 0;
}

.navbarContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
}


.navBarMenu {
    background-color: transparent;
    color: black;
    cursor: pointer;
    z-index: 50;

}

.navBarMenu[aria-expanded="true"] .bar:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
    margin-left: .5rem;
    width: 1.25rem;


}

.navBarMenu[aria-expanded="true"] .bar:nth-child(2) {
    margin-left: 1.5rem;
    transform: translateY(-1px) translateX(-1px) rotate(-45deg);
    width: 1.25rem;
}


.navBarMenu[aria-expanded="true"] .bar:nth-child(3) {
    margin-left: .99rem;
    margin-top: 1.35rem;
    transform: translateY(-9px) rotate(90deg);
    width: 1.25rem;
}


.navBarMenu[aria-expanded="true"] .bar:nth-child(4) {
    margin-top: -.35rem;
    margin-left: .95rem;
    transform: translateY(-1px) rotate(0deg);
    width: 1.25rem;
}


.bar {
    background-color: var(--color-primary-font);
    display: block;
    height: 3px;
    margin: 5px;
    width: 3rem;
}


.navDropdown {
    background: hsl(0 0% 100% / 0.1);
    backdrop-filter: blur(2rem);
    -webkit-background: hsl(0 0% 100% / 0.1);
    -webkit-backdrop-filter: blur(3rem);
    border: 2px solid var(--color-primary-font);
    display: none;

    opacity: 0;
    padding: min(11vh, 10rem) 1rem;

    z-index: -100;

    animation: fadeOut 1s;
    -webkit-animation: fadeOut 1s;
    transition: var(--transition-out);



}


.navDropdown.show {
    display: flex;
    flex-direction: column;
    gap: .75rem;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    height: auto;
    margin-top: -3rem;
    opacity: 1;
    padding-bottom: 5rem;
    pointer-events: auto;
    transition: var(--transition-in);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
}

.dodNav {
    color: var(--color-primary-font);
    font-size: clamp(1.25rem, 2vw, 2rem);
    font-weight: 700;
    text-shadow: var(--small-letter-shadow);
}

.dodNav:last-child {
    margin-bottom: -3rem;
}


.navbarLinks {
    color: var(--color-primary-font);
    font-size: clamp(1rem, 1.4vw, 2rem);
    font-weight: 400;
    margin-bottom: .15rem;
    text-shadow: var(--small-letter-shadow);
}



.navbarLinks:hover,
.dodNav:hover,
.navDropdown h2:hover {
    color: red;
}

.navline {
    border-top: 3px solid var(--color-primary-font);
}

.navDropdownByDrink {
    display: grid;
    grid-template-columns: 1fr 1fr;
}


/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    .bannerContainer {
        display: none;
    }

    .mobileNavContainer {
        /* align-items: center; */
        display: flex;
        justify-content: center;
        position: sticky;
        top: 13%;
        z-index: 200;
    }

    .navDropdown.show {
        margin-top: 0rem;
    }


}

/* =============== MEDIA QUERIES (SMALL HORIZONTAL DEVICES) =============== */
@media (max-width: 670px) and (orientation: landscape) {

    .navBarMenu[aria-expanded="true"] {
        margin-bottom: 5rem;
    }

    .navDropdown.show {
        height: 23rem;
        overflow-y: scroll;

    }


}


/* =============== MEDIA QUERIES (MED LANDSCAPE) =============== */
@media (max-width: 768px) and (orientation: landscape) {

    .navBarMenu[aria-expanded="true"] {
        margin-bottom: 1rem;
    }


}