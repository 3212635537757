.mustKnowSection {
    background-color: #000000;
    min-height: 100vh;
    padding-bottom: 2rem;

}

.mustKnowContainer {
    margin-bottom: 3rem;
    min-height: 100vh;

}

.mustKnowTitleContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 15rem;
    margin-bottom: 10rem;
}

.mustKnowTitleContainerH1 {
    font-size: clamp(4rem, 11vw, 13vw);
    margin-top: 4.5rem;
}

.mustKnowTitleContainerH1.hidden {
    opacity: 0;
    filter: blur(15px);
    transition: all 1s;
}

.mustKnowTitleContainerH1.show {
    filter: blur(0);
    opacity: 1;
    animation-duration: 2s;
    animation-name: animated-mock-title;
}

.mustKnowTitleContainerH2 {
    font-size: clamp(1.5rem, 3vw, 6vw);
    line-height: 0%;

}

.drinkGlassContainer {
    display: grid;
    height: 85vh;
    gap: 1rem;
    grid-template-columns: repeat(5, 18%);
    justify-content: center;
    margin-top: 3rem;
    overflow-y: scroll;
    padding-bottom: 4rem;

}

.drinkGlass h2 {
    font-size: clamp(.95rem, 1.25rem, 3.75vw);
    padding: 1rem .50rem;
    text-align: center;
    color: white;
    cursor: pointer;
    position: relative;
    z-index: 100;
    ;
}


.drinkGlass {
    width: 200px;
    height: 200px;
    background-color: #A24324;
    border-radius: 0 0 40px 40px;
    box-shadow: inset 0 0 50px #B87F42;
    position: relative;
    overflow: hidden;
    border: 2px solid white;

}

.drinkGlass::before {
    position: absolute;
    content: "";
    width: 200%;
    height: 200%;
    background-color: #000;
    top: -150%;
    left: -50%;
    border-radius: 40%;
    animation: wave 12s infinite linear;
}

.drinkGlass::after {
    position: absolute;
    content: "";
    width: 204%;
    height: 204%;
    background-color: #ececec08;
    top: -150%;
    left: -52%;
    border-radius: 40%;
    animation: wave 12s infinite linear;
    animation-delay: 0.7s;

}


@keyframes wave {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
    .drinkGlassContainer {
        grid-template-columns: repeat(3, 30%);
        margin-top: -6.5rem;
    }

    .drinkGlass {
        width: 150px;
        height: 150px;


    }

    .drinkGlass:after {
        border-radius: 15px 15px 900px 900px;
        bottom: -2.5px;
        left: -.05rem;

    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    .mustKnowSection {
        margin-top: -3rem;
    }

    .mustKnowContainer {
        padding-top: 8rem;
    }

    .mustKnowTitleContainerH2 {
        line-height: 1.5rem;
        text-align: center;
    }

    .drinkGlassContainer {
        margin-top: -5.5rem;

    }

    .drinkGlassContainer {
        grid-template-columns: repeat(2, 40%);
    }

    .drinkGlass {
        width: 150px;
        height: 150px;
    }


    .drinkGlass:after {
        border-radius: 15px 15px 900px 900px;
        bottom: -7.95px;
        left: -auto;
    }

}

/* =============== MEDIA QUERIES (SMALL LANDSCAPE ) =============== */
@media (max-width: 670px) and (orientation: landscape) {
    .drinkGlassContainer {
        margin-top: -12rem;

    }

}