.contactSection {
    align-items: center;
    background-color: black;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    margin-top: -5rem;
    width: 100%;
}


.contactContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
}


.contactTitleContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: auto;

}

.contactTitleContainer h1 {
    font-size: clamp(4rem, 11vw, 13vw);
    margin-top: 4.5rem;
}

.contactTitleContainer h3 {
    font-size: clamp(1rem, 1.75vw, 4.75vw);
    line-height: 0%;
}

.formContainer {
    margin-top: 4.5rem;
    width: 40vw;
}



form {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.15rem;
    margin-bottom: 2rem;


}

.contactForm input,
textarea {
    background: transparent;
    border: 2px solid var(--color-primary-font);
    color: var(--color-primary-font);
    border-radius: 10px;
    padding: 1.5rem;
    position: relative;
    resize: none;
    width: clamp(20rem, 40rem, 65vw);
}

.formContainer input::placeholder {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    font-size: .75rem;
}

.formContainer textarea::placeholder {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    font-size: .75rem;


}

.formContainer input.contactFormInput {
    height: 2rem;
    position: relative;
    /* border: 2px solid red; */
}


.contactForm {
    position: relative;
}


.emailSent {
    color: var(--color-primary-font);
    font-size: 1.25rem;
}

.buttonLight {
    background-color: transparent;
    border: 1px solid var(--color-primary-font);
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
    border-radius: 10px;
    color: var(--color-primary-font);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    outline: none;
    padding: .35rem 1.25rem;
    transition: var(--transition);
}

.buttonLight:focus {
    outline: 3px solid #148fd6;
    outline-offset: 2px;
    box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
}

.buttonLight:hover {
    background-color: #148fd6;
    /* Adjust color for hover state */
}

.emailSent {
    text-transform: capitalize;
}

.spinner {
    animation: spin 1s infinite linear;
    color: var(--color-primary-font);
    height: 2rem;
    transition: var(--transition);
    width: 2rem;


}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }

    to {
        transform: scale(1) rotate(360deg);
    }
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
    .contactSection {
        margin-top: -11rem;
    }

    .contactTitleContainer {
        margin-top: 4.5rem;
    }

    .contactTitleContainer h3 {
        text-align: center;
        line-height: 100%;
    }

    textarea {
        height: 8rem;
    }


}