.indexBackground {
    /* 
    background-size: cover; 
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-position: center; */

    min-height: 100vh;
    margin-top: -5rem;
    overflow-x: hidden;
    width: 100;
}

.custom-bg {
    height: 100%;
    width: 100vw;
}




#heroSection {
    margin-top: -5rem;
    width: 100%;
    height: 100vh;
}


.homePageContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}


.titleContainer {
    align-items: center;
    display: flex;
    flex-direction: column;

}


.titleContainer h1 {
    font-size: clamp(4rem, 12.5vw, 14.5vw);
    margin-bottom: .05rem;
}


.titleContainer h2 {
    font-size: clamp(2rem, 3.5vw, 6.5vw);
    line-height: 0%;
}


.horizonalDivider.hidden {
    opacity: 0;
    filter: blur(5px);
    transition: all 1s;
}

.horizonalDivider.show {
    animation-name: animate-underline;
    animation-duration: 2s;
    border-top: 7px solid white;
    color: var(--color-primary-font);
    filter: blur(0);
    margin-top: -2.5rem;
    opacity: 1;
    width: clamp(15rem, 45rem, 47vw);
    z-index: 20;
}


.titleContainerH1.hidden {
    opacity: 0;
    filter: blur(5px);
    transition: all 1s;


}

.titleContainerH1.show {
    filter: blur(0);
    opacity: 1;
    animation-duration: 2s;
    animation-name: animate-text-two;
}



.titleContainerH2.hidden {
    opacity: 0;
    filter: blur(5px);
    transition: all 1s;

}

.titleContainerH2.show {
    filter: blur(0);
    opacity: 1;

    animation-duration: 2s;
    animation-name: animate-text;
}


@media(perfers-reduced-motion) {
    .hidden {
        transition: none;
    }
}

@keyframes animate-text {
    0% {
        transform: translateX(-200%)
    }

    100% {
        transform: translateX(0%)
    }


}

@keyframes animate-text-two {
    0% {
        transform: translateX(200%)
    }

    100% {
        transform: translateX(0%)
    }

}

@keyframes animate-underline {

    0% {
        transform: translatey(1000%)
    }

    100% {
        transform: translatey(0%)
    }

}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media (max-width: 1024px) {

    .homePageContainer {
        height: 100vh;
    }


    .horizonalDivider.show {
        margin-top: -.3rem;

    }


}



/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media (max-width: 600px) {


    .custom-bg {
        height: 100vh;
        width: auto;

    }

    #heroSection {
        margin-top: -10rem;

    }

    .horizonalDivider.show {
        margin-top: -.55rem;
    }

}