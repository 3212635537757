.TermsSection {
    align-items: center;
    background-color: black;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    margin-top: -5rem;
    width: 100%;

}

.termsContainer {
    color: var(--color-primary-font);
    display: flex;
    align-items: center;
    justify-content: center;

}


.termsTitleContainer h1 {
    font-size: clamp(4rem, 9vw, 11vw);
    margin-top: 8rem;
    text-align: center;

}

.termsParagraphContainer {
    margin-top: 3rem;
    margin-bottom: 3rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    margin-top: 3rem;
    width: 70%;
}

.termsParagraphContainer p {
    font-size: clamp(1rem, 1.25vw, 3vw);
    text-align: justify;
}

.termsParagraphContainer h3 {
    margin-top: 1.25rem;
    text-decoration: underline;

}

.formLink {
    color: white;
    text-decoration: underline;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    .TermsSection {
        margin-top: -11rem;
    }



}