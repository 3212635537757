.tooltip_container {
    position: relative;
    display: inline-block;
    background-color: transparent;
    border: 1px solid var(--color-primary-font);
    border-radius: 10px;
    color: var(--color-primary-font);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 2rem;
    outline: none;
    text-align: center;
    text-transform: capitalize;
    padding: .25rem;
    width: clamp(8.5rem, 11rem, 12vw);

}

.tooltip {
    background-color: var(--color-primary-font);

    border-radius: 10px;
    color: rgb(0, 0, 0);
    margin-top: .75rem;
    padding: .5rem .5rem .5rem .5rem;
    position: absolute;

}

.tooltip_container:hover {
    z-index: 999;
}


.tooltip_two {
    border: 2px solid #566573;
    border-radius: 10px;
    color: #abb2b9;
    font-size: clamp(.85rem, 1rem, 40vw);

    margin: .25rem 0;
    padding: .25rem .50rem;
    transition: var(--transition-out);
    width: 90%;
    animation: fadeIn 0.4s ease-in-out;


}





@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}



/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    .tooltip_container {
        padding: .2rem;
        margin-top: 1rem;

    }
}